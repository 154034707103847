import { createSlice } from '@reduxjs/toolkit';
import { DashboardType } from 'types/mdm';

interface DashboadState {
  dashboard: DashboardType;
}

const initialState: DashboadState = {
  dashboard: {} as DashboardType
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboard(state, action) {
      state.dashboard = action.payload.result;
    }
  }
});

export const { setDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;
